import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


const TextBot = (props) => {
    const {
        alphabets,
        newPathsortOrder,
        selectedIndex,
        handleTextBox,
        removeTextBox,
        onChangeInput,
        handletextmessage,
        botData,
        handleProactiveCheckbox,
        proActive,
        printToLetter,
        inputWidth,
        delay,
        handledelayDropdown,
    } = props

    return (
        <div>
            <div className="mb-4 text-start p-4 path-name-input d-flex justify-content-start align-items-center">
                <p className="mb-0 me-2 text-gray text-box-alphabets">{printToLetter(selectedIndex).toUpperCase()}:</p>
                {onChangeInput()}
            </div>
            <div className="text-box-container p-4 pb-0">
                <DragDropContext>
                    <Droppable droppableId="droppable-2">
                        {(provided, snapshot) => (
                            <>
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <div className='bot-icon-container'>
                                        {newPathsortOrder[selectedIndex]?.messageList.map((item, index) => {
                                            return <Draggable key={index} draggableId={'draggable' + index} index={index}>
                                                {(provided, snapshot) => (

                                                    <div ref={provided.innerRef}
                                                        {...provided.draggableProps}>
                                                        <div
                                                            className={`mb-3 text-box-column text-box-column-${selectedIndex}`}>


                                                            <div>
                                                                <textarea className='textBox' value={item}
                                                                    onChange={(e) => handletextmessage(e.target.value, index)}
                                                                    placeholder="Enter text here..." />
                                                            </div>
                                                            <div className="text-box-actions-column ">
                                                                <div type="button" data-bs-toggle="modal"
                                                                    data-bs-target={`#textBoxModal${index}`}>
                                                                    <svg className='delete-svg-icon' version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30" >

                                                                        <g>
                                                                            <path class="st0" d="M7.37,29.9c-0.6-0.15-1.13-0.38-1.5-0.75c-0.6-0.6-0.75-1.28-0.83-2.03c-0.23-3-0.53-6.08-0.75-9.08   c-0.23-2.48-0.38-4.95-0.6-7.43c-0.08-0.6-0.08-1.2-0.15-1.8c0-0.08-0.08-0.23-0.23-0.3c-1.05-0.45-1.73-1.5-1.58-2.55   c0.08-1.2,0.9-2.18,2.03-2.4C4,3.48,4.3,3.48,4.52,3.48c1.28,0,2.55,0,3.9,0c0.08,0,0.23,0,0.38,0c0-0.3,0-0.53,0-0.75   c0-1.58,1.13-2.7,2.78-2.7c2.25,0,4.58,0,6.83,0c1.58,0,2.7,1.13,2.78,2.7c0,0.23,0,0.53,0,0.75c0.15,0,0.23,0,0.38,0   c1.35,0,2.63,0,3.98,0c1.28,0,2.25,0.75,2.63,1.95c0.38,1.2-0.23,2.55-1.43,3.08c-0.23,0.08-0.3,0.15-0.3,0.38   c-0.15,2.03-0.3,4.05-0.53,6.16c-0.15,2.03-0.3,4.05-0.53,6.08c-0.15,2.03-0.38,3.98-0.45,6.01c-0.08,0.98-0.3,1.88-1.2,2.4   c-0.38,0.23-0.75,0.3-1.13,0.45C17.36,29.9,12.4,29.9,7.37,29.9z M5.27,8.66c0.15,1.73,0.3,3.38,0.38,5.03   c0.15,2.25,0.38,4.5,0.53,6.68c0.15,2.25,0.38,4.43,0.53,6.68c0.08,0.75,0.38,0.98,1.13,0.98c4.65,0,9.31,0,13.89,0   c0.08,0,0.15,0,0.3,0c0.45,0,0.75-0.3,0.83-0.75c0-0.15,0.08-0.3,0.08-0.53c0.08-1.28,0.23-2.55,0.3-3.83   c0.15-2.03,0.38-4.05,0.53-6.08c0.15-1.8,0.3-3.53,0.45-5.33c0.08-0.98,0.15-1.95,0.23-2.93C18.11,8.66,11.65,8.66,5.27,8.66z    M14.88,6.93c3.45,0,6.83,0,10.28,0c0.15,0,0.23,0,0.38,0c0.6-0.08,0.98-0.68,0.68-1.28c-0.23-0.38-0.53-0.53-0.98-0.53   c-6.91,0-13.81,0-20.79,0c-0.08,0-0.15,0-0.3,0C3.7,5.2,3.32,5.58,3.32,6.03c0,0.45,0.38,0.83,0.83,0.9c0.15,0,0.23,0,0.38,0   C8.05,6.93,11.43,6.93,14.88,6.93z M19.31,3.4c0-0.3,0-0.53,0-0.75c0-0.68-0.38-0.98-0.98-0.98c-0.83,0-1.65,0-2.48,0   c-1.43,0-2.85,0-4.35,0c-0.53,0-0.9,0.3-0.98,0.75c-0.08,0.3,0,0.68,0,1.05C13.45,3.4,16.31,3.4,19.31,3.4z" />
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="">

                                                        </div>

                                                        <div className="modal fade" id={`textBoxModal${index}`}
                                                            tabIndex="-1" aria-labelledby="textBoxModalLabel"
                                                            aria-hidden="true">
                                                            <div className="modal-dialog">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Delete this Dialogue?</h5>
                                                                        <button type="button" className="btn-close"
                                                                            data-bs-dismiss="modal"
                                                                            aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <p>When the path is deleted any buttons that go to
                                                                            it will no longer go anywhere.
                                                                        </p>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary"
                                                                            data-bs-dismiss="modal">Close
                                                                        </button>
                                                                        <button type="button" data-bs-dismiss="modal"
                                                                            aria-label="Close"
                                                                            className="btn btn-primary"
                                                                            onClick={() => removeTextBox(index)}>Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                            </Draggable>
                                        })}
                                        <div className='col-2 bot-icon'>
                                            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30"  >

                                                <g>
                                                    <path class="st0" d="M12.67,14.89c0-1.63-1.33-2.96-2.96-2.96s-2.96,1.33-2.96,2.96s1.33,2.96,2.96,2.96S12.67,16.52,12.67,14.89z    M9.7,16.42c-0.85,0-1.53-0.69-1.53-1.53s0.69-1.53,1.53-1.53s1.53,0.69,1.53,1.53S10.55,16.42,9.7,16.42z" />
                                                    <path class="st0" d="M20.19,11.92c-1.63,0-2.96,1.33-2.96,2.96s1.33,2.96,2.96,2.96c1.63,0,2.96-1.33,2.96-2.96   S21.83,11.92,20.19,11.92z M20.19,16.42c-0.85,0-1.53-0.69-1.53-1.53s0.69-1.53,1.53-1.53s1.53,0.69,1.53,1.53   S21.04,16.42,20.19,16.42z" />
                                                    <path class="st0" d="M28.04,12.67h-0.89V11.1c0-1.4-1.14-2.55-2.55-2.55h-8.69V6.14c1.29-0.32,2.25-1.48,2.25-2.86   c0-1.63-1.33-2.96-2.96-2.96s-2.96,1.33-2.96,2.96c0,1.39,0.96,2.54,2.25,2.86v2.41H5.8c-1.41,0-2.55,1.14-2.55,2.55v1.57H1.96   C0.88,12.67,0,13.55,0,14.63v3.51c0,1.08,0.88,1.96,1.96,1.96h1.29v3.82c0,1.4,1.14,2.55,2.55,2.55h1.7l-0.15,3.23l5.54-3.23H24.6   c1.4,0,2.55-1.14,2.55-2.55v-3.82h0.89c1.08,0,1.96-0.88,1.96-1.96v-3.51C30,13.55,29.12,12.67,28.04,12.67z M13.67,3.27   c0-0.85,0.69-1.53,1.53-1.53s1.53,0.69,1.53,1.53s-0.69,1.53-1.53,1.53S13.67,4.12,13.67,3.27z M1.96,18.67   c-0.29,0-0.53-0.24-0.53-0.53v-3.51c0-0.29,0.24-0.53,0.53-0.53h1.29v4.56H1.96z M25.72,23.91c0,0.62-0.5,1.12-1.12,1.12H12.5   l-3.6,2.1l0.1-2.1H5.8c-0.62,0-1.12-0.5-1.12-1.12V11.1c0-0.62,0.5-1.12,1.12-1.12H24.6c0.62,0,1.12,0.5,1.12,1.12V23.91z    M28.57,18.14c0,0.29-0.24,0.53-0.53,0.53h-0.89V14.1h0.89c0.29,0,0.53,0.24,0.53,0.53V18.14z" />
                                                    <path class="st0" d="M17.95,19.04c-0.39,0-0.71,0.32-0.71,0.71c0,0.83-1.04,1.53-2.28,1.53s-2.28-0.7-2.28-1.53   c0-0.39-0.32-0.71-0.71-0.71c-0.39,0-0.71,0.32-0.71,0.71c0,1.63,1.67,2.96,3.71,2.96s3.71-1.33,3.71-2.96   C18.66,19.36,18.34,19.04,17.95,19.04z" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>

                                    {provided.placeholder}



                                </div>
                                <div className='text-end'>
                                    <button
                                        className={` btn add-input-button p-0 mb-1 ${selectedIndex === 0 ? "d-none" : null}`}
                                        onClick={() => handleTextBox()}>
                                        + Add
                                    </button>
                                </div>
                            </>



                        )}
                    </Droppable>
                </DragDropContext>

            </div>
            {selectedIndex == 0 ? (
                <div className='d-flex flex-row align-items-center proactive-checkbox justify-content-start pb-2'>
                    <div class="form-check me-4 d-flex flex-row align-items-center proactive-checkbox-container">
                        <input class="form-check-input cursor-pointer" type="checkbox" value="" id="flexCheckDefault" onChange={() => handleProactiveCheckbox(!botData.proActive)} defaultChecked={botData.proActive} />
                        <label class="form-check-label ms-2 mt-1" for="flexCheckDefault">
                            Set as Proactive message.
                        </label>
                    </div>
                    <div className='d-flex flex-row justify-content-center align-items-center delay-container'>
                        <label className='me-2'>Delay</label>
                        <select disabled={!proActive} value={botData.delay} class="form-select input-field me-2" aria-label="Default select example" onChange={(e) => handledelayDropdown(e.target.value)}>
                            <option value={botData.value} selected>0{botData.proActive}</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                        <label>Seconds</label>
                    </div>
                </div>
            ) : null}
            <hr style={{ backgroundColor: 'rgba(0,0,0,.1)', opacity: 1 }} />
        </div>
    )

}


export default TextBot;