import React from 'react';


class ReplyButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputWidth: ''
        }
    }


    render() {
        const { addInputFields, handleReplyInput, handleReplyDropDown, handleDragReply } = this.props;
        return (
            <div className='flex-none d-flex flex-column'>
                <div className='flex-fill d-flex flex-row mt-3 row'>
                    <div className="col-lg-6 col-xl-5">
                        <div className=" text-button-container ms-3 pb-4 pt-4">
                            <div className="d-flex flex-column align-items-end">
                                {handleReplyInput()}
                                <div className="add-input-button" role="button" onClick={() => addInputFields()}>
                                    + Add
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 text-start">
                        <div className='ps-4'>
                            <div className="next-path-heading">
                                Next Dialogues:
                            </div>
                            {handleReplyDropDown()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReplyButton;