import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


const DialogName = (props) => {
    const {
        alphabets,
        selectedIndex,
        newPathsortOrder,
        handleNewButton,
        onChangeInputValue,
        handleOnDragEnd,
        handleSelectedIndex,
        removeNewInput,
        printToLetter,
        isNewDialogueLoading
    } = props;
    return (
        <div className="dialogue-editor-sidebar inputs-column p-4">
            <DragDropContext onDragEnd={(props) => handleOnDragEnd(props)}>
                <Droppable droppableId="droppable-1">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            {...provided.dragHandleProps}
                        >
                            {newPathsortOrder.map((item, index) => {
                                return (
                                    <>
                                        {index === 0 ? (
                                            <div
                                                role="button"
                                                setitemArray
                                                className="d-flex align-items-center"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                onClick={() => handleSelectedIndex(index)}
                                            >

                                                <span className='alphabets'>{printToLetter(index).toUpperCase()}.</span>

                                                <div
                                                    className={selectedIndex === index ? `file-box file-box-${index} selected` : `file-box file-box-${index}`}
                                                    onClick={() => {
                                                        onChangeInputValue(index)
                                                    }}>
                                                    <div className='dialogue-name-container'>

                                                        <div className="text-start dialogue-path-name"
                                                            key={index}>{item.dialogueName}
                                                        </div>
                                                    </div>
                                                    <div className="delete-option-svg" type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#exampleModal${index}`}>
                                                        <svg className="interface-icon o__standard o__standard__trash"
                                                            width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M6.4 1a.4.4 0 00-.4.4v.17c-1.467.099-2.64.279-3.178.371a.39.39 0 00-.322.387V3.6c0 .22.18.4.4.4h10.2a.4.4 0 00.4-.4V2.328a.39.39 0 00-.322-.387A32.048 32.048 0 0010 1.57V1.4a.4.4 0 00-.4-.4H6.4zm6.1 4l-.47 8.918c-.018.36-.234.674-.583.76C10.869 14.822 9.79 15 8 15s-2.869-.178-3.447-.322c-.35-.087-.565-.4-.584-.76L3.5 5h9z"></path>
                                                        </svg>
                                                    </div>

                                                </div>

                                                <div className="modal fade" id={`exampleModal${index}`}
                                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">Remove Dialogue</h5>
                                                                <button type="button" className="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <p>Do you want to delete the Dialogue?
                                                                </p>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary"
                                                                    data-bs-dismiss="modal">Close
                                                                </button>
                                                                <button type="button" data-bs-dismiss="modal"
                                                                    aria-label="Close" className="btn btn-primary"
                                                                    onClick={() => removeNewInput(index)}>Save
                                                                    changes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <Draggable key={item.index} draggableId={'draggable' + index} index={index}>
                                                {(provided, snapshoforEacht) => (
                                                    <div
                                                        role="button"
                                                        setitemArray
                                                        className="d-flex align-items-center"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        onClick={() => handleSelectedIndex(index)}
                                                    >

                                                        <span className='alphabets'>{printToLetter(index).toUpperCase()}.</span>

                                                        <div
                                                            className={selectedIndex === index ? `file-box file-box-${index} selected` : `file-box file-box-${index}`}
                                                            onClick={() => {
                                                                onChangeInputValue(index)
                                                            }}>
                                                            <div className='dialogue-name-container d-flex flex-row align-items-center justify-content-between'>
                                                                <div className='dialogue-name-dragicon d-flex flex-row align-items-center'>
                                                                    <div className="me-1" {...provided.dragHandleProps}>
                                                                        <svg className="drag-svg-icon" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30" >
                                                                            <g>
                                                                                <path class="st0" d="M0.1,21c0-1.7,1.3-3,3-3s3,1.3,3,3c0,1.7-1.3,3-3,3C1.4,24,0.1,22.6,0.1,21z" />
                                                                                <path class="st0" d="M27,12c-1.7,0-3-1.3-3-3c0-1.7,1.4-3,3-3c1.6,0,3,1.4,3,3C29.9,10.7,28.6,12,27,12z" />
                                                                                <path class="st0" d="M27,18c1.7,0,3,1.3,3,3s-1.3,3-3,3c-1.7,0-3-1.3-3-3C24,19.3,25.3,18,27,18z" />
                                                                                <path class="st0" d="M3,12c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3c1.6,0,3,1.3,3,3C6,10.7,4.7,12,3,12z" />
                                                                                <path class="st0" d="M15,12c-1.6,0-3-1.3-3-3s1.4-3,3-3c1.6,0,3,1.4,3,3C18,10.7,16.6,12,15,12z" />
                                                                                <path class="st0" d="M15,24c-1.6,0-3-1.3-3-3s1.3-3,3-3c1.6,0,3,1.3,3,3C18,22.6,16.6,24,15,24z" />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                    <div className="dialogue-path-name text-start"
                                                                        key={index}>{item.dialogueName}</div>
                                                                </div>
                                                                <div className="delete-option-svg" type="button"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target={`#exampleModal${index}`}>
                                                                    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30" >

                                                                        <g>
                                                                            <path class="st0" d="M7.37,29.9c-0.6-0.15-1.13-0.38-1.5-0.75c-0.6-0.6-0.75-1.28-0.83-2.03c-0.23-3-0.53-6.08-0.75-9.08   c-0.23-2.48-0.38-4.95-0.6-7.43c-0.08-0.6-0.08-1.2-0.15-1.8c0-0.08-0.08-0.23-0.23-0.3c-1.05-0.45-1.73-1.5-1.58-2.55   c0.08-1.2,0.9-2.18,2.03-2.4C4,3.48,4.3,3.48,4.52,3.48c1.28,0,2.55,0,3.9,0c0.08,0,0.23,0,0.38,0c0-0.3,0-0.53,0-0.75   c0-1.58,1.13-2.7,2.78-2.7c2.25,0,4.58,0,6.83,0c1.58,0,2.7,1.13,2.78,2.7c0,0.23,0,0.53,0,0.75c0.15,0,0.23,0,0.38,0   c1.35,0,2.63,0,3.98,0c1.28,0,2.25,0.75,2.63,1.95c0.38,1.2-0.23,2.55-1.43,3.08c-0.23,0.08-0.3,0.15-0.3,0.38   c-0.15,2.03-0.3,4.05-0.53,6.16c-0.15,2.03-0.3,4.05-0.53,6.08c-0.15,2.03-0.38,3.98-0.45,6.01c-0.08,0.98-0.3,1.88-1.2,2.4   c-0.38,0.23-0.75,0.3-1.13,0.45C17.36,29.9,12.4,29.9,7.37,29.9z M5.27,8.66c0.15,1.73,0.3,3.38,0.38,5.03   c0.15,2.25,0.38,4.5,0.53,6.68c0.15,2.25,0.38,4.43,0.53,6.68c0.08,0.75,0.38,0.98,1.13,0.98c4.65,0,9.31,0,13.89,0   c0.08,0,0.15,0,0.3,0c0.45,0,0.75-0.3,0.83-0.75c0-0.15,0.08-0.3,0.08-0.53c0.08-1.28,0.23-2.55,0.3-3.83   c0.15-2.03,0.38-4.05,0.53-6.08c0.15-1.8,0.3-3.53,0.45-5.33c0.08-0.98,0.15-1.95,0.23-2.93C18.11,8.66,11.65,8.66,5.27,8.66z    M14.88,6.93c3.45,0,6.83,0,10.28,0c0.15,0,0.23,0,0.38,0c0.6-0.08,0.98-0.68,0.68-1.28c-0.23-0.38-0.53-0.53-0.98-0.53   c-6.91,0-13.81,0-20.79,0c-0.08,0-0.15,0-0.3,0C3.7,5.2,3.32,5.58,3.32,6.03c0,0.45,0.38,0.83,0.83,0.9c0.15,0,0.23,0,0.38,0   C8.05,6.93,11.43,6.93,14.88,6.93z M19.31,3.4c0-0.3,0-0.53,0-0.75c0-0.68-0.38-0.98-0.98-0.98c-0.83,0-1.65,0-2.48,0   c-1.43,0-2.85,0-4.35,0c-0.53,0-0.9,0.3-0.98,0.75c-0.08,0.3,0,0.68,0,1.05C13.45,3.4,16.31,3.4,19.31,3.4z" />
                                                                        </g>
                                                                    </svg>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="modal fade" id={`exampleModal${index}`}
                                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Delete this
                                                                            Path?</h5>
                                                                        <button type="button" className="btn-close"
                                                                            data-bs-dismiss="modal"
                                                                            aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <p>When the path is deleted any buttons that go
                                                                            to it will no longer go anywhere.
                                                                        </p>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button"
                                                                            className="btn btn-secondary"
                                                                            data-bs-dismiss="modal">Close
                                                                        </button>
                                                                        <button type="button" data-bs-dismiss="modal"
                                                                            aria-label="Close"
                                                                            className="btn btn-primary "
                                                                            onClick={() => removeNewInput(index)}>Delete</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                            </Draggable>
                                        )}
                                    </>
                                )
                            })}
                            {provided.placeholder}
                        </div>

                    )}
                </Droppable>
            </DragDropContext>
            <div className='add-new-path-button-container'>
                <button disabled={isNewDialogueLoading} className="new-path-button mt-3" onClick={() => handleNewButton()}>Add new Dialogue</button>
            </div>
        </div>
    )
};

export default DialogName;