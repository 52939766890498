import { useEffect, useState } from "react";
import { Link, useNavigate, } from "react-router-dom";
import axios from 'axios';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


const BotList = (props) => {
    const [data, setData] = useState("");
    const [listOfBots, setListOfBots] = useState([]);
    const [urlData, setUrlData] = useState([]);
    const [editedBot, seteditedBot] = useState([]);
    const [editExistingBotName, setEditExistingBotName] = useState('');
    const [editExistingBotUrl, setEditExistingBotUrl] = useState("")
    const [botSortOrder, setBotSortOrder] = useState([]);
    const history = useNavigate();
    const [padContent, setPadContent] = useState(false);
    const [apiData, setApidata] = useState([]);
    const [updatedbot, setUpdatedBot] = useState([]);
    const [mediaQuery, setMediaQuery] = useState(false);
    const [isLoading, SetIsLoading] = useState(false)
    const baseurl = "https://chatbot.engagebay.com/api";


    let nId = getNameSpaceId()

    useEffect(() => {
        SetIsLoading(true)
        axios.get(`${baseurl}/bot/all?nid=${nId}`).then((results) => {
            setListOfBots(results.data)

            if (results.data.length == 0) {
                setPadContent(true)
            }
            else {
                window && window.parent && window.parent.window.setAccountBotStatus(results.data.filter(bot => bot.published == true).length);
                setPadContent(false)
            }
        }).catch((err) => {
            console.log(err, "err");
        }).finally(() => {
            SetIsLoading(false)
        })


        if (window.innerWidth <= 767) {
            setMediaQuery(true)
        }
        else {
            setMediaQuery(false)
        }



    }, [apiData, updatedbot]);


    function getNameSpaceId() {
        if (!window.parent || !window.parent.isLocalDevEnv || (window.parent && window.parent.isLocalDevEnv())) {
            return 567
        }
        else {
            return window && window.parent && window.parent.window.CURRENT_USER_JSON.domainId;
        }


    }

    useEffect(() => {
        setBotSortOrder(listOfBots)
    }, [listOfBots, editedBot]);


    function newBotName() {
        SetIsLoading(true)
        const botname = data ? data : "Hello there";
        axios.post(`${baseurl}/bot`, {
            botName: botname,
            namespaceId: nId,
            triggerPageUrl: [`${urlData}`],
            isPublished: false,
        }).then((res) => {
            setListOfBots(res.data)
            history("/chatbot/" + res.data.id + "/" + res.data.botName, { state: res.data });
        }).catch(error => {
            alert(error);
            return error;
        }).finally(() => {
            SetIsLoading(false)
        })
    }


    function deleteBot(botId) {
        axios.delete(`${baseurl}/bot/${botId}`).then((res) => {
            setApidata([...listOfBots]);

        })
    }


    function parentRouteUpdate(id, name) {
        window.parent.backboneNavigatetoRoute("botlist/" + id + "/" + name);
        return true;
    }

    function editBotName(value, botIndex) {
        const editBot = listOfBots;
        editBot[botIndex].botName = value
        seteditedBot(editBot[botIndex])
        setEditExistingBotName(value)

    }

    function editBotUrl(value, botIndex) {
        const editBot = listOfBots;
        editBot[botIndex].triggerPageUrl[0] = value
        seteditedBot(editBot[botIndex])
        seteditedBot(editBot[botIndex])
    }


    function editBot(botIndex) {
        axios.put(`${baseurl}/bot`, editedBot).then((res) => {
            setUpdatedBot(res.data)

        })
    }


    function handleDragBotList(result) {
        const items = Array.from(botSortOrder);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setBotSortOrder(items);
    }


    function handlePublish(state1, index) {


        const editBot = listOfBots;

        editBot[index].published = state1;
        seteditedBot(editBot[index]);
        setBotSortOrder([...editBot])
    }

    function formatUpdatedTimeZone(time) {
        const modifiedTime = new Date(time).toLocaleTimeString().toString()
        const formattedDate = new Date(time).toLocaleDateString().toString()
        return <span>{`${formattedDate}\u00A0${modifiedTime} `}</span>
    }

    function formatCreatedTimeZone(time) {
        const modifiedTime = new Date(time).toLocaleTimeString().toString()
        const formattedDate = new Date(time).toLocaleDateString().toString()
        return <span>{`${formattedDate}\u00A0${modifiedTime} `}</span>
    }

    function clearInputs() {
        console.log("hello");
        setData("")
        setUrlData("")
    }



    return (

        <div className="eb-bot-bg-container">
            {!isLoading ? (
                <div className="container-fluid px-0">
                    <div className="page-head">
                        <h2 className="text-start page-heading">
                            Bots<span className="small">({`${listOfBots.length}`})</span>
                        </h2>
                        <div>
                            <button type="button" className="btn btn-primary create-bot-button" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">
                                Create Bot
                            </button>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Create New Bot</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p className="modal-label">Bot Name</p>
                                    <input className="modal-input" placeholder="Enter Bot Name"
                                        value={data} onChange={(e) => setData(e.target.value)} />
                                    <p className="modal-label">Website URL</p>
                                    <input className="modal-input" placeholder="Enter the website URL"
                                        value={urlData} onChange={(e) => setUrlData(e.target.value)} />
                                </div>
                                <div className="modal-footer">
                                    <button onClick={() => clearInputs()} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                        onClick={() => newBotName()}> Create Bot
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-content">


                        {!padContent ? (
                            <>

                                <div className="card">
                                    <div className="table-responsive">

                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '65px' }}>

                                                    </th>
                                                    <th>Title</th>
                                                    <th scope="col">State</th>
                                                    <th scope="col">Last updated at</th>
                                                    <th>Created at</th>
                                                </tr>
                                            </thead>

                                            <DragDropContext onDragEnd={(props) => handleDragBotList(props)}>
                                                <Droppable droppableId="droppable-3">
                                                    {(provided, snapshot) => (
                                                        <>
                                                            <tbody
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                {...provided.dragHandleProps}
                                                            >

                                                                {botSortOrder.map((item, index) => {
                                                                    return <>
                                                                        <Draggable key={item.index} draggableId={'draggable' + index}
                                                                            index={index}>
                                                                            {(provided, snapshoforEacht) => (

                                                                                <tr ref={provided.innerRef}
                                                                                    {...provided.draggableProps} key={index}
                                                                                >
                                                                                    <td {...provided.dragHandleProps}>
                                                                                        <svg className="drag-svg-icon" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30" >

                                                                                            <g>
                                                                                                <path className="st0" d="M0.1,21c0-1.7,1.3-3,3-3s3,1.3,3,3c0,1.7-1.3,3-3,3C1.4,24,0.1,22.6,0.1,21z" />
                                                                                                <path className="st0" d="M27,12c-1.7,0-3-1.3-3-3c0-1.7,1.4-3,3-3c1.6,0,3,1.4,3,3C29.9,10.7,28.6,12,27,12z" />
                                                                                                <path className="st0" d="M27,18c1.7,0,3,1.3,3,3s-1.3,3-3,3c-1.7,0-3-1.3-3-3C24,19.3,25.3,18,27,18z" />
                                                                                                <path className="st0" d="M3,12c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3c1.6,0,3,1.3,3,3C6,10.7,4.7,12,3,12z" />
                                                                                                <path className="st0" d="M15,12c-1.6,0-3-1.3-3-3s1.4-3,3-3c1.6,0,3,1.4,3,3C18,10.7,16.6,12,15,12z" />
                                                                                                <path className="st0" d="M15,24c-1.6,0-3-1.3-3-3s1.3-3,3-3c1.6,0,3,1.3,3,3C18,22.6,16.6,24,15,24z" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </td>
                                                                                    {mediaQuery ? (<td>
                                                                                        <div
                                                                                        >
                                                                                            <div data-bs-toggle="tooltip" data-bs-placement="top" title={item.botName} className="eb-botName">{item.botName}</div>
                                                                                        </div>
                                                                                    </td>)
                                                                                        :
                                                                                        (<td>
                                                                                            <Link to={`/chatbot/${item.id}/${item.botName}`}
                                                                                                onClick={() => parentRouteUpdate(item.id, item.botName)}
                                                                                                state={{ from: item }}>
                                                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title={item.botName} className="eb-botName">{item.botName}</div>
                                                                                            </Link>
                                                                                        </td>)}

                                                                                    <td>
                                                                                        <div className={`state-status ${botSortOrder[index] && botSortOrder[index].published ? 'badge-light-primary' : 'badge-light-danger'}`}>
                                                                                            {botSortOrder[index] && botSortOrder[index].published ? "Published" : "Draft"}
                                                                                        </div>

                                                                                    </td>
                                                                                    <td>
                                                                                        <div >
                                                                                            {formatUpdatedTimeZone(listOfBots[index] && listOfBots[index].modifiedAt)}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="created-action-row">
                                                                                            {formatCreatedTimeZone(listOfBots[index] && listOfBots[index].createdAt)}
                                                                                            <div className="action-icon-container">
                                                                                                <div onClick={() => {
                                                                                                    setEditExistingBotName(item.botName);
                                                                                                    setEditExistingBotUrl(item.triggerPageUrl)
                                                                                                }} className="edit-icon" type="button"
                                                                                                    data-bs-toggle="modal"
                                                                                                    data-bs-target={`#exampleModal${index}`}>
                                                                                                    <svg className="edit-svg-icon" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30">

                                                                                                        <path className="st0" d="M28.71,4.79l-3.52-3.52c-1.65-1.65-4.42-1.65-6.07,0l-18,17.91L0,29.97l10.79-1.12L28.7,10.94  C30.43,9.21,30.43,6.44,28.71,4.79z M17.16,5.24l7.57,7.57L11.47,26.15L3.82,18.5L17.16,5.24z M1.57,28.4l0.9-8.54l0.37-0.37  l7.65,7.65l-0.3,0.3L1.57,28.4z M27.73,9.88l-1.95,1.95l-7.65-7.65l1.95-1.95c1.12-1.12,2.92-1.12,4.05,0l3.52,3.52  C28.86,6.96,28.86,8.76,27.73,9.88z" />
                                                                                                    </svg>
                                                                                                </div>

                                                                                                <div className="modal fade"
                                                                                                    id={`exampleModal${index}`}
                                                                                                    aria-labelledby="exampleModalLabel"
                                                                                                    aria-hidden="true">
                                                                                                    <div className="modal-dialog">
                                                                                                        <div className="modal-content">
                                                                                                            <div className="modal-header">
                                                                                                                <h5 className="modal-title"
                                                                                                                    id="exampleModalLabel">Edit
                                                                                                                    Bot </h5>
                                                                                                                <button type="button"
                                                                                                                    className="btn-close"
                                                                                                                    data-bs-dismiss="modal"
                                                                                                                    aria-label="Close"></button>
                                                                                                            </div>
                                                                                                            <div className="modal-body">
                                                                                                                <p className="modal-label">Bot
                                                                                                                    Name</p>
                                                                                                                <input className="modal-input"
                                                                                                                    placeholder="Bot Name"
                                                                                                                    value={editExistingBotName}
                                                                                                                    onChange={(e) => editBotName(e.target.value, index)} />
                                                                                                                <p className="modal-label">Website
                                                                                                                    URL</p>
                                                                                                                <input className="modal-input"
                                                                                                                    placeholder="Enter the website URL"
                                                                                                                    value={editExistingBotUrl}
                                                                                                                    onChange={(e) => editBotUrl(e.target.value, index)} />

                                                                                                                <div className="switch-handler">
                                                                                                                    <label className="modal-label">Publish</label>
                                                                                                                    <div
                                                                                                                        className="form-check form-switch">
                                                                                                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked1"
                                                                                                                            onChange={() => handlePublish(!botSortOrder[index].published, index)}
                                                                                                                            defaultChecked={botSortOrder[index].published} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="modal-footer">
                                                                                                                <button type="button"
                                                                                                                    className="btn btn-secondary"
                                                                                                                    data-bs-dismiss="modal">Close
                                                                                                                </button>
                                                                                                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => editBot(index)} > Save</button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="delete-icon"
                                                                                                    data-bs-toggle="modal"
                                                                                                    data-bs-target={`#exampleModal${item.id}`}>
                                                                                                    <svg className='delete-svg-icon' version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30" >

                                                                                                        <g>
                                                                                                            <path className="st0" d="M7.37,29.9c-0.6-0.15-1.13-0.38-1.5-0.75c-0.6-0.6-0.75-1.28-0.83-2.03c-0.23-3-0.53-6.08-0.75-9.08   c-0.23-2.48-0.38-4.95-0.6-7.43c-0.08-0.6-0.08-1.2-0.15-1.8c0-0.08-0.08-0.23-0.23-0.3c-1.05-0.45-1.73-1.5-1.58-2.55   c0.08-1.2,0.9-2.18,2.03-2.4C4,3.48,4.3,3.48,4.52,3.48c1.28,0,2.55,0,3.9,0c0.08,0,0.23,0,0.38,0c0-0.3,0-0.53,0-0.75   c0-1.58,1.13-2.7,2.78-2.7c2.25,0,4.58,0,6.83,0c1.58,0,2.7,1.13,2.78,2.7c0,0.23,0,0.53,0,0.75c0.15,0,0.23,0,0.38,0   c1.35,0,2.63,0,3.98,0c1.28,0,2.25,0.75,2.63,1.95c0.38,1.2-0.23,2.55-1.43,3.08c-0.23,0.08-0.3,0.15-0.3,0.38   c-0.15,2.03-0.3,4.05-0.53,6.16c-0.15,2.03-0.3,4.05-0.53,6.08c-0.15,2.03-0.38,3.98-0.45,6.01c-0.08,0.98-0.3,1.88-1.2,2.4   c-0.38,0.23-0.75,0.3-1.13,0.45C17.36,29.9,12.4,29.9,7.37,29.9z M5.27,8.66c0.15,1.73,0.3,3.38,0.38,5.03   c0.15,2.25,0.38,4.5,0.53,6.68c0.15,2.25,0.38,4.43,0.53,6.68c0.08,0.75,0.38,0.98,1.13,0.98c4.65,0,9.31,0,13.89,0   c0.08,0,0.15,0,0.3,0c0.45,0,0.75-0.3,0.83-0.75c0-0.15,0.08-0.3,0.08-0.53c0.08-1.28,0.23-2.55,0.3-3.83   c0.15-2.03,0.38-4.05,0.53-6.08c0.15-1.8,0.3-3.53,0.45-5.33c0.08-0.98,0.15-1.95,0.23-2.93C18.11,8.66,11.65,8.66,5.27,8.66z    M14.88,6.93c3.45,0,6.83,0,10.28,0c0.15,0,0.23,0,0.38,0c0.6-0.08,0.98-0.68,0.68-1.28c-0.23-0.38-0.53-0.53-0.98-0.53   c-6.91,0-13.81,0-20.79,0c-0.08,0-0.15,0-0.3,0C3.7,5.2,3.32,5.58,3.32,6.03c0,0.45,0.38,0.83,0.83,0.9c0.15,0,0.23,0,0.38,0   C8.05,6.93,11.43,6.93,14.88,6.93z M19.31,3.4c0-0.3,0-0.53,0-0.75c0-0.68-0.38-0.98-0.98-0.98c-0.83,0-1.65,0-2.48,0   c-1.43,0-2.85,0-4.35,0c-0.53,0-0.9,0.3-0.98,0.75c-0.08,0.3,0,0.68,0,1.05C13.45,3.4,16.31,3.4,19.31,3.4z" />
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                </div>
                                                                                                <div className="modal fade"
                                                                                                    id={`exampleModal${item.id}`}
                                                                                                    aria-labelledby="exampleModalLabel"
                                                                                                    aria-hidden="true">
                                                                                                    <div className="modal-dialog">
                                                                                                        <div className="modal-content">
                                                                                                            <div className="modal-header">
                                                                                                                <h5 className="modal-title"
                                                                                                                    id="exampleModalLabel">Delete
                                                                                                                    Bot</h5>
                                                                                                                <button type="button"
                                                                                                                    className="btn-close"
                                                                                                                    data-bs-dismiss="modal"
                                                                                                                    aria-label="Close"></button>
                                                                                                            </div>
                                                                                                            <div className="modal-body">
                                                                                                                <span>
                                                                                                                    All data related to the Bot will be deleted.
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="modal-footer">
                                                                                                                <button type="button"
                                                                                                                    className="btn btn-secondary"
                                                                                                                    data-bs-dismiss="modal">Close
                                                                                                                </button>
                                                                                                                <button type="button"
                                                                                                                    className="btn btn-primary"
                                                                                                                    data-bs-dismiss="modal"
                                                                                                                    onClick={() => deleteBot(item.id)}> Delete
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </Draggable>
                                                                    </>
                                                                })}

                                                            </tbody>

                                                            {provided.placeholder}
                                                        </>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>


                                        </table>

                                    </div>
                                </div>

                            </>

                        ) :
                            <div className="card py-5">
                                <div className="card-body p-5">
                                    <div className="row">
                                        <div className="col-12 col-md-9 mx-auto">
                                            <div className="media align-items-start">
                                                <img className="me-4 pe-4" height="190" src="/pad-content-image.svg" alt="img" />
                                                <div className="media-body">
                                                    <h4 className="mt-0 mb-3">Hmm.. It looks like you haven’t configured a chatbot yet.</h4>
                                                    <p className="mb-0 lead fs-16 text-muted">
                                                        Streamlining your business processes enhances customer engagement and boosts productivity.
                                                        Configure a chatbot that is highly relevant your business.
                                                        Nowadays more businesses are deploying bots to convert users to customers, drive sales, and improve the overall consumer experience.                                                </p>
                                                    <a className="btn btn-primary mt-4" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal">Create Bot</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>

                </div>

            ) :
                <div className="p-3">
                    <img src="https://doxhze3l6s7v9.cloudfront.net/app/static//img/ajax-loader-cursor.gif" alt="gif" />
                </div>
            }

        </div >
    )


}


export default BotList;


