import Chatbot from "./Bot";
import BotList from './BotList';
import {HashRouter, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/js/dist/dropdown'


function App() {
    return (
        <div className="App">
            <HashRouter>
                <Routes>
                    <Route path="/botlist" element={<BotList/>}/>
                    <Route path="/chatbot/:botId/:botName" element={<Chatbot/>}/>
                </Routes>
            </HashRouter>
        </div>
    );
}

export default App;
